import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import RichText from '../components/richText';


const Promociones = (props) => {


  const showCrediton = true;
  const assets = 'assets'



  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Metodos
  const $promocionesList = props.data.prismic.allPromocioness.edges[0].node


  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);

    //Get open popup

    if (typeof window !== 'undefined') {

      let url = new URLSearchParams(window.document.location.search.substring(1));
      let pop = url.get('pop')
      let txtCondiciones = '';

      if (pop) {

        var $popupCondiciones = document.getElementsByClassName('js-popup-tyc')[0];

        const popup = document.querySelector(`.c-promotion[data-id='${pop}']`);

        if (popup) {
          txtCondiciones = popup.querySelector(`.js-condiciones-selected`).innerHTML;

          $popupCondiciones.style.display = 'block';

          $popupCondiciones.querySelector('.js-text-holder').innerHTML = txtCondiciones;

          $popupCondiciones.classList.add('is-open');
        }

      }

    }

  }, []);






  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-promociones'} >


        <SEO metadata={$metadata} title={'Promociones'}></SEO>

        <>


          <Helmet>


            <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=10`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=3`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=4`)} rel="stylesheet" type="text/css" />

            <link href={withPrefix(`../../${assets}/css/site/promotions.css?v=2`)} rel="stylesheet" type="text/css" />


          </Helmet>



          {/* HERO */}
          <div className="c-hero o-align-middle">
            <div className="c-hero__holder o-align-middle__aligner o-section">
              <div className="o-wrapper">
                <div className="js-header-waypoints" id="c-header-waypoint" />
                <div className="c-hero__megaphone--right">
                  <img src={`../../${assets}/images/promotions-hero-image-2@2.png`} alt="megaphone" width="100%" height="100%" />
                </div>
                <div className="c-hero__megaphone--left">
                  <img src={`../../${assets}/images/promotions-hero-image-1@2.png`} alt="megaphone" width="100%" height="100%" />
                </div>
                <h2 className="c-title c-title--big c-title--white">¡Promos {!showCrediton && 'plus'} <br /><span className="c-title--outline c-title--outline-white">para vos!</span></h2>
                {showCrediton && <span className="c-promotion__subtitle c-title c-title--white">Conocé todas las promociones<br /> que tenemos para vos.</span>}
              </div>
            </div>{/* .c-hero__holder */}
          </div>{/* .c-hero */}

          {/* PROMOTION */}
          <ul className="c-promotions">

            {$promocionesList.promicion.map((field, i) => (
              <li key={i} data-id={i} className="c-promotion o-section">
                <h3 className="u-hidden-for-seo">{field.titulo[0].text}</h3>
                <div className="c-promotion__holder o-wrapper">
                  {field.imagen && <div className="c-promotion__image" style={{ backgroundImage: `url(${field.imagen.url})` }} />}{/*
                    */}<div className="c-promotion__info">
                    <h4 className={`c-title c-title--small c-title--blue ${showCrediton && 'c-title--red'}`}>{field.titulo[0].text}</h4>
                    <div className="c-promotion__content c-text">


                      <RichText render={field.contenido} />

                    </div>
                    <a className="c-promotion__btn c-btn c-btn--wsp js-whatsapp-btn " data-promoid={field.promoid} href="#"><span className={`${showCrediton ? 'c-btn__icon' : 'c-btn--icon' } icon-wsp`} />Solicitar promo</a>


                    {field.condiciones && <a className={`c-promotion__btn c-btn c-btn--outline-blue ${showCrediton && 'c-btn--outline'} js-condiciones-btn`}>Términos y condiciones

                    <span class="js-condiciones-selected u-hidden-for-seo"><RichText render={field.condiciones} /></span>

                    </a>}


                  </div>{/* .c-promotion__info */}
                </div>{/* .c-promotion__holder */}
              </li>
            ))}

          </ul>

          {/* POP-UP BODY  */}
          <div className="c-info js-popup-tyc" >
            <div className="c-info__holder">
              <span className="c-info__close js-close-condiciones" />
              <h2 className="c-title c-title--small c-title--blue">Términos y condiciones</h2>

              <div className="s-content js-text-holder">

              </div>

            </div>
          </div>


        </>





      </Layout>


    </>
  );
}



export const query = graphql`
query PromocionesQuery {
          prismic {
        allMetadatas  {
          edges {
        node {
          ...MetadataFragment
        }
        }
      },
    allFooters {
          edges {
        node {
          ...FooterFragment
        }
        }
      },
      allPromocioness {
        edges {
          node {
            promicion {
              contenido
              imagen
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              titulo
              condiciones
              promoid
            }
          }
        }
      },
      allInformacion_financieras {
        edges {
          node {
            ...InformacionFinancieraFragment
          }
        }
      }
}
}
`


export default Promociones;

